export default {
  getImagePathById: function(id) {
    return id.substr(-1) + '/' + id.substr(-2) + '/' + id + '.jpg';
  },

  getImageUrl: function(id) {
    if (id && (typeof id === 'string' || id instanceof String)) {
      const folder = (window.elastic_index ? window.elastic_index : 'archivesbrest');
      return 'https://brest-crawler.nord-nord-ouest.com/data/' + folder + '/hd/' + this.getImagePathById(id);
    }
    return '';
  },

  getThumbUrl: function(id) {
    if (id && (typeof id === 'string' || id instanceof String)) {
      const folder = (window.elastic_index ? window.elastic_index : 'archivesbrest');
      return 'https://brest-crawler.nord-nord-ouest.com/data/' + folder + '/thumb/' + this.getImagePathById(id);
    }
    return '';
  }
}
