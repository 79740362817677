export default async function runRequest(body) {
  const url = "https://brest-admin.nord-nord-ouest.com/api/search";
  const response = await fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      index: (window.elastic_index ? window.elastic_index : 'archivesbrest'),
      body,
    })
  });
  return response.json();
}
