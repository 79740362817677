import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import OpenSeaDragon from "openseadragon";

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheckSquare
  , faArrowLeft
  , faSearchPlus
  , faArrowRight
  , faTimes
  , faCoffee
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Facet
  , PagingInfo
  , ResultsPerPage
  , SearchProvider
  , SearchBox
  , Results
  , Paging
  , Sorting
  , WithSearch
} from "@elastic/react-search-ui";

import {
  Layout
} from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

import HasImageCheckboxContainer from './components/HasImageCheckboxContainer'
import MultiCheckboxFacet from './components/MultiCheckboxFacet'
import tools from './tools'
import SortOptions from './SortOptions'
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import buildState from "./buildState";
import facetList from "./facetList";

import Spinner from './components/Spinner'
import Loader from './components/Loader'
import ResultView from './components/ResultView'

// import { useMousePosition } from "./useMousePosition";

const elasticConfig = {
  debug: true,
  onSearch: async state => {
    const { resultsPerPage } = state;
    const requestBody = buildRequest(state);
    // Note that this could be optimized by running all of these requests
    // at the same time. Kept simple here for clarity.
    const responseJson = await runRequest(requestBody);
    const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
      responseJson,
      state,
      facetList
    );
    return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
  },
  alwaysSearchOnInitialLoad: true,
  initialState: {
    resultsPerPage: 50,
    sortField: "date_created",
    sortDirection: "desc",
    /*
    filters: [
      {
        field: 'has_image',
        type: 'all',
        values: [true]
      }
    ]
    */
  }
}
const setStatus = async (id, status) => {
  const response = await fetch("https://brest-admin.nord-nord-ouest.com/api/set-status", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      index: (window.elastic_index ? window.elastic_index : 'archivesbrest'),
      id,
      body: {
        doc: {
          'u_status': status,
        }
      }
    })
  })
  .catch(function(error) {
    console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
  });
  var result = document.getElementById('result-' + id);
  result.style.display = 'none';
}

export default function App() {
  const hash = (window.location.hash) ? window.location.hash.substr(1) : null;
  const [currentId, setCurrentId] = useState(hash);
  // const [currentPreview, setCurrentPreview] = useState(null);
  // const currentPreview = null;
  // const [currentItem, setCurrentItem] = useState(null);
  const [viewer, setViewer] = useState( null);
  // const position = useMousePosition();
  // const position = {x:0, y:0};
  const onIndexChange = (e) => {
    window.elastic_index = e.target.value
  }
  const onClickNext = (ids, currentIndex) => {
    var index = ids.indexOf(currentIndex);
    var newId = ids[index + 1];
    if (newId) {
      setCurrentId(newId);
    }
  }
  const onClickPrevious = (ids, currentIndex) => {
    var index = ids.indexOf(currentIndex);
    var newId = ids[index - 1];
    if (newId) {
      setCurrentId(newId);
    }
  }
  const resultOnmousemove = (e) => {
    var t = e.currentTarget.querySelector(".thumb");
    var p = e.currentTarget.querySelector(".thumb-placeholder");
    if (t) {
      t.classList.add("focus");
      p.classList.add("visible");
      var offset = 30;
      e.clientX > .75 * window.innerWidth ? t.style.left = e.pageX - t.offsetWidth - offset + "px" : t.style.left = e.pageX + offset + "px";
      e.clientY > .5 * window.innerHeight ? t.style.top = e.pageY - t.offsetHeight - offset + "px" : t.style.top = e.pageY + offset + "px";
    }
  }
  const resultOnmouseleave = (e) => {
    var t = e.currentTarget.querySelector(".thumb");
    var p = e.currentTarget.querySelector(".thumb-placeholder");
    if (t) {
      t.classList.remove("focus")
      p.classList.remove("visible")
    }
  }
  const InitOpenseadragon = (img) => {
    // viewer && viewer.destroy();
    var options = {
      id: "openseadragon",
      zoomInButton:   "zoom-in",
      zoomOutButton:  "zoom-out",
      homeButton:     "home",
      fullPageButton: "toggle-fullscreen",
    };
    if (img) {
      options.tileSources = {
        type: 'image',
        url: img
      };
    }
    return OpenSeaDragon(options);
  };
  const onKeyPress = (e) => {
    if (e.key == "Escape") {
      document.getElementById("notice-close") && document.getElementById("notice-close").click();
    }
    if (e.key == "ArrowRight") {
      document.getElementById("notice-next") && document.getElementById("notice-next").click();
    }
    if (e.key == "ArrowLeft") {
      document.getElementById("notice-previous") && document.getElementById("notice-previous").click();
    }

  }
  const onViewerOpen = () => {
    document.getElementById("openseadragon-wrapper").classList.remove('is-loading');
  }
  useEffect(() => {
    if (viewer) {
      viewer.addHandler('open', onViewerOpen);
    }
    return () => {
        viewer && viewer.removeAllHandlers();
    };
  }, [viewer]);
  useEffect(() => {
    document.body.addEventListener('keydown', onKeyPress, false);
    setViewer(InitOpenseadragon(null));
    return () => {
        viewer && viewer.destroy();
    };
  }, []);
  const OpenSeaDragonClass = (currentId) ? '' : 'hidden';

  useEffect(() => {
    if (currentId) {
      document.getElementById("openseadragon-wrapper").classList.add('is-loading');
      if (!viewer) {
        InitOpenseadragon(tools.getImageUrl(currentId));
      }
      else {
        viewer.open([{
          type: 'image',
          url: tools.getImageUrl(currentId)
        }]);
      }
    }
    else {
      // document.body.removeEventListener('keydown', onKeyPress, false);
    }
  }, [currentId]);
  return (
    <div className="wrapper">

      <div id="openseadragon-wrapper" className={OpenSeaDragonClass}>
        <Loader />
        <div className="viewer-controls">
          <div id="zoom-in">+</div>
          <div id="zoom-out">-</div>
        </div>
        <div id="openseadragon"></div>
      </div>

      <SearchProvider config={elasticConfig}>
        <WithSearch mapContextToProps={(state) => (state)}>
          {(state) => {
            return (
              <div className="app">
                {currentId &&
                  <div className="openseadragon-notice">
                    {true &&
                      <div>
                        <div className="notice-controls">
                          <div id="notice-previous" className="previous" onClick={() => onClickPrevious(state.results.map((r) => r.id.raw), currentId)}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                            <span className="control-label">Précédent</span>
                          </div>
                          <div id="notice-next" className="next" onClick={() => onClickNext(state.results.map((r) => r.id.raw), currentId)}>
                            <span className="control-label">Suivant</span>
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                          <div id="notice-close" className="close" onClick={() => setCurrentId(null)}>
                            <FontAwesomeIcon icon={faTimes} />
                            <span className="control-label">Fermer</span>
                          </div>
                        </div>
                        <div className="buttons">
                          <button onClick={(e) => { e.stopPropagation(); setStatus(currentId, 'hors-scope'); document.getElementById("notice-next").click(); }}>Hors scope</button>
                          <button onClick={(e) => { e.stopPropagation(); setStatus(currentId, 'bof'); document.getElementById("notice-next").click(); }}>Bof</button>
                          <button onClick={(e) => { e.stopPropagation(); setStatus(currentId, 'ok'); document.getElementById("notice-next").click(); }}>Ok</button>
                        </div>
                        <div className="notice-data">
                          <h1>{ state.results.filter((r) => r.id.raw === currentId)[0].u_source_id.raw }</h1>
                          {Object.entries(state.results.filter((r) => r.id.raw === currentId)[0]).filter(([fieldKey, fieldValue]) => fieldValue).map(([fieldName, fieldValue]) => (
                              <div key={fieldName} className="field">
                                <div className="field-label">{fieldName}</div>
                                <div
                                  className="field-value"
                                  dangerouslySetInnerHTML={{ __html: (fieldValue.raw ? fieldValue.raw : fieldValue) }}
                                />
                              </div>
                          ))}
                        </div>
                      </div>
                    }
                  </div>
                }

                  <Layout
                    header={<div></div>}
                    bodyHeader={
                      <>
                        {state.isLoading && <Spinner />}
                        {!state.isLoading && state.wasSearched && <PagingInfo />}
                        {!state.isLoading && state.wasSearched && <Paging />}
                        {<ResultsPerPage options={[50, 100]} />}
                      </>
                    }
                    bodyContent={
                      <>
                        {state.isLoading && <Loader />}
                        {state.wasSearched &&
                          <>
                            {!state.isLoading && <div className="results">
                              {state.results.map(r => (
                                    <div
                                        className="result"
                                        id={'result-' + r.id.raw}
                                        key={r.id.raw}
                                        onClick={() => setCurrentId(r.id.raw)}
                                        onMouseMove={resultOnmousemove}
                                        onMouseLeave={resultOnmouseleave}
                                      >
                                      <div>{r.u_has_image.raw ? <div><img className="thumb" src={r.img} /><span style={{width:(35*r.image_ratio)}} className="thumb-placeholder"><FontAwesomeIcon icon={faSearchPlus} /></span></div> : ''}</div>
                                      <div>
                                        {r.id.raw}
                                      </div>
                                      <div>
                                        {r.u_title.raw}
                                      </div>
                                      {false &&
                                        <div>
                                          {r.periodeHistorique ? r.periodeHistorique.raw : ''}
                                        </div>
                                      }
                                    </div>
                              ))}
                            </div>}
                          </>
                        }
                      </>
                    }
                    bodyFooter={
                      <>
                        {state.wasSearched && !state.isLoading &&
                          <div className="footer-wrapper">
                            {state.isLoading && <Spinner />}
                            {!state.isLoading && state.wasSearched && <PagingInfo />}
                            {!state.isLoading && state.wasSearched && <Paging />}
                            {<ResultsPerPage options={[50, 100]} />}
                          </div>
                        }
                      </>
                    }
                    sideContent={
                      <div>
                        <select onChange={onIndexChange}>
                          <option value="archivesbrest">Archives de Brest</option>
                          <option value="museedebretagne">Musée de Bretagne</option>
                          <option value="gallica">BNF Gallica / Yroise</option>
                        </select>
                        <br/>
                        <br/>
                        <SearchBox
                          autocompleteSuggestions={false}
                        />
                        {state.wasSearched && <>
                          <br/>
                          {(state.filters.length > 0 || state.resultSearchTerm) &&
                            <div className="reset-search" onClick={() => { state.setSearchTerm("", {shouldClearFilters: true});}}><span>&#10005;</span>Réinitialiser la recherche</div>
                          }
                          <HasImageCheckboxContainer />
                          <br/>
                          <Sorting label={"Trier par"} sortOptions={SortOptions} />
                          {facetList.map((field) => (
                            <Facet view={MultiCheckboxFacet} filterType="any" field={field} label={field} isFilterable={true} />
                          ))}
                        </>}
                      </div>
                    }
                  />
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </div>
  );
}
                          // <Facet filterType="any" field={'keywords_auteurs'} label={'keywords_auteurs'} isFilterable={true} />
                          // <Facet filterType="any" field="keywords_lieux" label="keywords_lieux" isFilterable={true} />
                          // <Facet filterType="any" field="periodeHistorique" label="periodeHistorique" isFilterable={true} />
