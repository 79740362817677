import React from 'react';

import { withSearch } from "@elastic/react-search-ui";

export function HasImageCheckboxContainer({ isLoading, geoData, filters, facets, addFilter, setFilter }) {
  const hasImageOnly = filters.reduce(function(acc, filter) {
    if (filter.field === 'has_image' && filter.values[0] === true) {
      acc = true;
    }
    return acc;
  }, false)

  const unknownStatusOnly = filters.reduce(function(acc, filter) {
    if (filter.field === 'unknown_status' && filter.values[0] === true) {
      acc = true;
    }
    return acc;
  }, false)
  return (
    <div className="special-checkboxes">
      <fieldset className="sui-facet">
        <div className="sui-multi-checkbox-facet">
          <label htmlFor="has_image" className="sui-multi-checkbox-facet__option-label">
            <div className="sui-multi-checkbox-facet__option-input-wrapper">
              <input id="has_image" type="checkbox" className="sui-multi-checkbox-facet__checkbox" checked={hasImageOnly} onChange={() => setFilter('has_image', !hasImageOnly)}/>
              <span className="sui-multi-checkbox-facet__input-text">Avec image uniquement</span>
            </div>
          </label>
        </div>
      </fieldset>
      <fieldset className="sui-facet">
        <div className="sui-multi-checkbox-facet">
          <label htmlFor="unknown_status" className="sui-multi-checkbox-facet__option-label">
            <div className="sui-multi-checkbox-facet__option-input-wrapper">
              <input id="unknown_status" type="checkbox" className="sui-multi-checkbox-facet__checkbox" checked={unknownStatusOnly} onChange={() => setFilter('unknown_status', !unknownStatusOnly)}/>
              <span className="sui-multi-checkbox-facet__input-text">À traiter uniquement</span>
            </div>
          </label>
        </div>
      </fieldset>
    </div>
  );
}



export default withSearch(
  ({ isLoading, geoData, filters, facets, addFilter, removeFilter, setFilter, a11yNotify }) => ({
    isLoading,
    geoData,
    filters,
    facets,
    addFilter,
    removeFilter,
    setFilter,
    a11yNotify
  })
)(HasImageCheckboxContainer);
