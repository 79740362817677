export default [
  {
    name: "Les plus récentes",
    value: "date_created",
    direction: "desc"
  },
  {
    name: "Pertinence",
    value: "",
    direction: ""
  },
  {
    name: "Les plus anciennes",
    value: "date_created",
    direction: "asc"
  }
];
