const getFacets = () => {
  switch (window.elastic_index) {
    case 'museedebretagne':
      return [];
    case 'archivesbrest':
    default:
      return [
        'keywords_thesaurusicono.keyword'
        , 'sousSerie'
        // , 'nombreDImage'
        , 'keywords_lieux'
        , 'keywords_edifices'
        , 'keywords_ips'
        , 'keywords_organismes.keyword'
        , 'keywords_personnes'
        , 'keywords_thesaurus'
        , 'periodeHistorique.keyword'
        , 'typeDocument.keyword'
        , 'technique.keyword'
        , 'keywords_auteurs'
      ];
      break;
  }
}
const facets = getFacets();
export default facets;
